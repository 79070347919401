
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import PopUp from "@/components/PopUp.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import utils from "@/utils/utils";

@Component({
  components: { Header, Loader, PopUp, FilterComponent },
})
export default class UserManagement extends Vue {
  public currentPage = 1;
  loading = true;
  public selected_permissions: any = [];
  public permissionObject: any = {};
  permissionId = null;
  permissionTitle = '';
  saveData = true;
  error: any = "";
  screenCheckboxStates: any = {};
  permissionIdForEdit = null;
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  public permission_options: any = [];
  group_options = [];
  public module_options: any = [];
  onCheckboxChange(item: any) {

  }
  selectAll = false;
  selectAllPermissions() {
    for (const item of this.selected_permissions) {
      item.create = this.selectAll;
      item.view = this.selectAll;
      item.edit = this.selectAll;
      item.delete = this.selectAll;
    }
  }
  retreive() {
    this.loading = true;
    UserManagementService.get_all_screens()
      .then((res) => {
        this.selected_permissions = res.data.data;
        this.saveData = true;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  get rows() {
    return this.selected_permissions.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.selected_permissions.slice(start, end);
  }
  getPermission() {
    this.loading = true;
    UserManagementService.get_screen_permission()
      .then((res) => {
        this.permission_options = res.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  mounted() {
    this.retreive();
    this.getPermission();
  }
  savePermission() {
    const selectedScreens = this.selected_permissions.filter(
      (permission: any) =>
        permission.create || permission.view || permission.edit || permission.delete
    );
    let isChecked = false;
    this.selected_permissions.forEach((item: any) => {
      if (item.create || item.view || item.edit || item.delete) {
        isChecked = true;
      }
    });
    const permissionObject: any = {
      title: this.permissionTitle,
      client_permission: {},
      screen: [],
      server_permission: {}
    };
    this.error = ""
    const error_list = utils.getEmptyKeys(this.permissionObject, [])
    if (error_list.length > 0 || !this.permissionTitle.trim() || !isChecked) {
      const additionalErrors = [];
      if (!isChecked) {
        additionalErrors.push('Check any one permission for a screen');
      }
      if (!this.permissionTitle.trim()) {
        additionalErrors.push('permissionTitle');
      }
      this.error = utils.generateErrorMessage(error_list.concat(additionalErrors));
      // this.error = utils.generateErrorMessage(error_list)
    }
    else {
      this.selected_permissions.forEach((permission: any) => {
        const screenPermission = {
          create: permission.create || false,
          view: permission.view || false,
          update: permission.edit || false,
          delete: permission.delete || false,
        };

        permissionObject.client_permission[permission.name] = screenPermission;
        // permissionObject.server_permission[permission.module_name__api_route] = screenPermission
        // permissionObject.screen.push(permission.id);
        // const moduleRoutes = permission.module_name__api_route;
        // moduleRoutes.forEach((route: any) => {
        //     permissionObject.server_permission[route] = screenPermission;
        // });
      });

      selectedScreens.forEach((permission: any) => {
        const screenPermission = {
          create: permission.create || false,
          view: permission.view || false,
          update: permission.edit || false,
          delete: permission.delete || false,
        };
        permissionObject.screen.push(permission.id);
        const moduleRoutes = permission.module_name__api_route;
        moduleRoutes.forEach((route: any) => {
          permissionObject.server_permission[route] = screenPermission;
        });
      });
      // if (this.errorMessages.length === 0) {
      this.loading = true;
      UserManagementService.post_screen_permission(permissionObject)
        .then((res) => {
          this.resetPermissions();
          this.getPermission();
          this.refreshFilters();
          this.showModal("Created Successfully", [], "success");
          this.loading = false;
          this.selectAll = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  retreivePermission() {
    this.saveData = false;
    const selectedPermission: any = this.permission_options.find(
      (permission: any) => permission.id === this.permissionId
    );

    if (selectedPermission) {
      this.permissionTitle = selectedPermission.title;
      this.permissionObject.id = selectedPermission.id;
      this.permissionIdForEdit = selectedPermission.id;

      this.selected_permissions.forEach((screen: any) => {
        const screenName = screen.name;
        screen.view = false;
        screen.create = false;
        screen.edit = false;
        screen.delete = false;


        if (selectedPermission.client_permission && selectedPermission.client_permission[screenName]) {
          screen.view = selectedPermission.client_permission[screenName].view;
          screen.create = selectedPermission.client_permission[screenName].create;
          screen.edit = selectedPermission.client_permission[screenName].update;
          screen.delete = selectedPermission.client_permission[screenName].delete;

          this.screenCheckboxStates[screenName] = {
            view: screen.view,
            create: screen.create,
            edit: screen.edit,
            delete: screen.delete,
          };
        }
      });
    }
  }

  editPermission() {
    const selectedScreens = this.selected_permissions.filter(
      (permission: any) =>
        permission.create || permission.view || permission.edit || permission.delete
    );
    let isChecked = false;
    this.selected_permissions.forEach((item: any) => {
      if (item.create || item.view || item.edit || item.delete) {
        isChecked = true;
      }
    });
    this.error = ""
    const error_list = utils.getEmptyKeys(this.permissionObject, [])
    if (error_list.length > 0 || !this.permissionTitle.trim() || !isChecked) {
      const additionalErrors = [];
      if (!isChecked) {
        additionalErrors.push('Check any one permission for a screen');
      }
      if (!this.permissionTitle.trim()) {
        additionalErrors.push('permissionTitle');
      }
      this.error = utils.generateErrorMessage(error_list.concat(additionalErrors));
    }
    else {
      const permissionObject: any = {
        title: this.permissionTitle,
        client_permission: {},
        screen: [],
        server_permission: {}
      };
      this.selected_permissions.forEach((permission: any) => {
        const screenPermission = {
          create: permission.create || false,
          view: permission.view || false,
          update: permission.edit || false,
          delete: permission.delete || false,
        };

        permissionObject.client_permission[permission.name] = screenPermission;

        // permissionObject.server_permission[permission.module_name__api_route] = screenPermission
        // const moduleRoutes = permission.module_name__api_route;
        // moduleRoutes.forEach((route: any) => {
        //     permissionObject.server_permission[route] = screenPermission;
        // });
      });
      selectedScreens.forEach((permission: any) => {
        const screenPermission = {
          create: permission.create || false,
          view: permission.view || false,
          update: permission.edit || false,
          delete: permission.delete || false,
        };
        permissionObject.screen.push(permission.id);
        const moduleRoutes = permission.module_name__api_route;
        moduleRoutes.forEach((route: any) => {
          permissionObject.server_permission[route] = screenPermission;
        });
      });

      this.loading = true;
      UserManagementService.update_screen_permission(this.permissionObject.id, permissionObject)
        .then((res) => {
          this.retreive()
          this.getPermission();
          this.resetPermissions();
          this.refreshFilters();
          this.showModal("Updated Successfully", [], "success");
          this.loading = false;
          this.getRoutes();
          this.selectAll = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  deletePermission() {
    UserManagementService.delete_screen_permission(this.permissionObject.id)
      .then((res) => {
        this.resetPermissions();
        this.refreshFilters();
        this.retreive();
        this.getPermission();
        this.showModal("Deleted Successfully", [], "success")
      })
      .catch((e) => {
        this.loading = false;
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  resetPermissions() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
    this.permissionTitle = '';
    this.selected_permissions = [];
    this.permissionId = null;
    this.retreive();
    this.saveData = true;
    this.error = "";
    this.permissionIdForEdit = null;
  }
  filter_fields = [
    { type: "text", label: "Screen", name: "screen", value: "", param_name: "name", filter_type: "simple", },
  ];

  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;

      if (this.permissionIdForEdit === null) {
        FilterService.get_screens(simpleFilteredObj).then((data) => {
          if (
            data.data.data !== undefined &&
            data.data.data !== null
          ) {
            this.selected_permissions = data.data.data;
            this.loading = false;
          } else {
            this.retreive();
          }
        });
      } else {
        FilterService.get_screens(simpleFilteredObj).then((data) => {
          if (
            data.data.data !== undefined &&
            data.data.data !== null
          ) {
            this.selected_permissions = data.data.data.map((screen: any) => {
              const screenName = screen.name;
              if (this.screenCheckboxStates[screenName]) {
                screen.view = this.screenCheckboxStates[screenName].view;
                screen.create = this.screenCheckboxStates[screenName].create;
                screen.edit = this.screenCheckboxStates[screenName].edit;
                screen.delete = this.screenCheckboxStates[screenName].delete;
              }
              return screen;
            });
            this.loading = false;
          }
        });
      }
    }
  }

  refreshFilters() {
    this.resetPermissions();
  }
  getRoutes() {
    UserManagementService.get_routes()
      .then((res) => {
        // this.routes = res.data.data;
        const localRoutes = JSON.stringify(res.data.data)
        localStorage.setItem('route', localRoutes)
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
